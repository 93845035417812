import { Component, Input, OnDestroy } from '@angular/core';
import {
  getCurrentDateUnixTimestamp,
  getCurrentDateUnixTimestampBasedOnSystemID,
  TimeInterval,
} from '@twaice-fe/shared/utilities';
import { TimeRangeInterface } from './time-selector.models';
import { debounceTime, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { updateQueryParameter } from '@twaice-fe/frontend/shared/utilities';
import { SystemsService } from '@twaice-fe/frontend/shared/services';

export const DEFAULT_TIME_RANGE_MAPPING: TimeRangeInterface[] = [
  { id: 'thirtyDays', value: TimeInterval.THIRTY_DAYS / 1000, label: 'Last 30 days', disabled: false },
  { id: 'sixtyDays', value: (2 * TimeInterval.THIRTY_DAYS) / 1000, label: 'Last 60 days', disabled: false },
  { id: 'ninetyDays', value: (3 * TimeInterval.THIRTY_DAYS) / 1000, label: 'Last 90 days', disabled: false },
];

@Component({
  selector: 'twaice-fe-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrl: './time-selector.component.scss',
})
export class TimeSelectorComponent implements OnDestroy {
  @Input() timeRangeMapping = [...DEFAULT_TIME_RANGE_MAPPING];
  selectedTimeRange = DEFAULT_TIME_RANGE_MAPPING[1];
  selectedTimeRangeIndex = 1;
  private endDate: number;

  constructor(
    protected store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private systemService: SystemsService
  ) {
    this.observeRouteParams();
  }

  ngOnDestroy(): void {
    const currentParams = this.router.routerState.snapshot.root.queryParams;

    this.router.navigate([], {
      queryParams: { systemID: currentParams?.['systemID'] },
      queryParamsHandling: null,
    });
  }

  async onTimeRangeChange(selectedTimeRangeIndex: number) {
    this.selectedTimeRange = this.timeRangeMapping[selectedTimeRangeIndex];
    await this.updateQueryParameter(
      ['timeRange', 'startDate', 'endDate'],
      [this.selectedTimeRange.id, this.endDate - this.selectedTimeRange.value, this.endDate]
    );
  }

  private handleTimeRangeFromQueryParams(timeRange: string) {
    const indexTimeRange = this.timeRangeMapping.findIndex((item) => item.id === timeRange);
    if (indexTimeRange !== -1) {
      this.selectedTimeRangeIndex = indexTimeRange;
      this.selectedTimeRange = this.timeRangeMapping[indexTimeRange];
    } else {
      this.selectedTimeRangeIndex = 1;
      this.selectedTimeRange = this.timeRangeMapping[1];
    }
  }

  private observeRouteParams() {
    this.systemService
      .getCurrentSystemIdObservable()
      .pipe(
        debounceTime(100),
        filter((id) => id != null)
      )
      .subscribe(async (systemID) => {
        this.endDate = getCurrentDateUnixTimestampBasedOnSystemID(systemID);
        const search = new URL(window.location.href).searchParams;
        const timeRange = search.get('timeRange') ?? this.selectedTimeRange.id;
        this.handleTimeRangeFromQueryParams(timeRange);
        await this.updateQueryParameter(
          ['timeRange', 'startDate', 'endDate'],
          [
            timeRange,
            getCurrentDateUnixTimestampBasedOnSystemID(systemID) - this.selectedTimeRange.value,
            getCurrentDateUnixTimestampBasedOnSystemID(systemID),
          ]
        );
      });
  }

  private updateQueryParameter(paramNames: string[], values: unknown[]): Promise<boolean> {
    return updateQueryParameter(this.router, this.route, paramNames, values);
  }
}
