import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, filter, map, mergeMap, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import * as IncidentsDetectionActions from '../actions/incidents-detection.actions';
import * as IncidentsDetectionSelectors from '../selectors/incidents-detection.selectors';

import { Store } from '@ngrx/store';
import { IncidentsDetectionService } from '@twaice-fe/frontend/shared/services';
import { RequestQueryBuilder } from '@twaice-fe/frontend/shared/utilities';
import { configsSelectors, systemSelectors } from '../selectors';
import { IncidentComponentOverview } from '@twaice-fe/shared/models';
import { keysToSnake } from '@twaice-fe/shared/utilities';

@Injectable()
export class IncidentsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          IncidentsDetectionActions.fetchIncidents,
          IncidentsDetectionActions.updateIncidentListConfiguration,
          IncidentsDetectionActions.sortIncidents,
          IncidentsDetectionActions.filterIncidents,
          IncidentsDetectionActions.resetIncidentFilters,
        ]
      ),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getIncidentsState)),
      switchMap(([_action, state]) => {
        const { page, limit, order, filter: filters } = state?.config ?? { page: 1, limit: 20 };
        const formatedFilter = keysToSnake(filters || {});
        const params = new RequestQueryBuilder().build({ page, limit, order, ...formatedFilter });
        return this.incidentsDetectionService.fetchIncidents(params).pipe(
          map(({ data }) => IncidentsDetectionActions.loadIncidentsSuccess({ incidents: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentsDetectionActions.loadIncidentsFailure({ error }));
          })
        );
      })
    )
  );

  fetchAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[IncidentsDetectionActions.fetchIncidentsAnalytics, IncidentsDetectionActions.filterIncidentsAnalytics]),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getIncidentsState)),
      switchMap(([_, state]) => {
        const filters = state?.config?.filter ?? {};
        const formatedFilter = keysToSnake(filters);
        const params = new RequestQueryBuilder().build({ page: 1, limit: 500, ...formatedFilter });
        return this.incidentsDetectionService.fetchIncidentsAnalytics(params).pipe(
          map(({ data }) => IncidentsDetectionActions.loadIncidentsAnalyticsSuccess({ analytics: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentsDetectionActions.loadIncidentsFailure({ error }));
          })
        );
      })
    )
  );

  fetchSingleIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchSingleIncident),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getSingleIncident)),
      switchMap(([params, state]) =>
        (state ? of({ data: state }) : this.incidentsDetectionService.fetchSingleIncident(params)).pipe(
          map(({ data }) => IncidentsDetectionActions.loadSingleIncidentSuccess({ incident: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Single Incidents]', error);
            return of(IncidentsDetectionActions.loadSingleIncidentFailure({ error }));
          })
        )
      )
    )
  );

  fetchStorageOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchStorageOverview),
      withLatestFrom(
        this.store.select(configsSelectors.getCustomerBk),
        this.store.select(systemSelectors.getSelected),
        this.store.select(IncidentsDetectionSelectors.getIncidentsState)
      ),
      filter(([customerBk, system]) => !!customerBk && !!system),
      switchMap(([_, customerBk, system, state]) =>
        this.incidentsDetectionService
          .fetchStorageOverview({
            customerBk: customerBk as string,
            systemBk: system?.rootContainerId as string,
            startTime: state.config.filter['startTimeFrom'],
            endTime: state.config.filter['endTimeTo'],
          })
          .pipe(
            map((result) => IncidentsDetectionActions.loadStorageOverviewSuccess({ overview: result.data })),
            catchError((error) => {
              console.error('[Error/Fetch Storage Overview]', error);
              return of(IncidentsDetectionActions.loadStorageOverviewFailure({ error }));
            })
          )
      )
    )
  );

  fetchComponentOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchComponentOverview),
      withLatestFrom(
        this.store.select(configsSelectors.getCustomerBk),
        this.store.select(systemSelectors.getSelected),
        this.store.select(IncidentsDetectionSelectors.getIncidentsState)
      ),
      filter(([customerBk, system]) => !!customerBk && !!system),
      mergeMap(([action, customerBk, system, state]) =>
        this.incidentsDetectionService
          .fetchComponentOverview({
            customerBk: customerBk as string,
            systemBk: system?.rootContainerId as string,
            startTime: state.config.filter['startTimeFrom'],
            endTime: state.config.filter['endTimeTo'],
            ...(action.parentComponentBk && {
              parentComponentBk: action.parentComponentBk,
              level: 'string',
            }),
          })
          .pipe(
            map((componentOverview) =>
              IncidentsDetectionActions.loadComponentOverviewSuccess({
                overview: this.parseOverview(componentOverview.data),
                parentComponentBk: action.parentComponentBk,
              })
            ),
            catchError((error) => {
              console.error('[Error/Fetch Component Overview]', error);
              return of(IncidentsDetectionActions.loadComponentOverviewFailure({ error }));
            })
          )
      )
    )
  );

  fetchMailConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchMailNotificationConfig),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter((system) => !!system),
      switchMap(([_, system]) =>
        this.incidentsDetectionService
          .fetchMailConfiguration({
            customerBk: system!.customerId as string,
            systemBk: system!.rootContainerId as string,
          })
          .pipe(
            map((mailConfigResponse) =>
              IncidentsDetectionActions.fetchMailNotificationConfigSuccess({
                mailConfig: { is_enabled: mailConfigResponse.data.is_enabled },
              })
            ),
            catchError((error) => of(IncidentsDetectionActions.fetchMailNotificationConfigFailure({ error })))
          )
      )
    )
  );

  updateMailConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.updateMailNotificationConfig),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter((system) => !!system),
      switchMap(([action, system]) =>
        this.incidentsDetectionService
          .updateMailConfiguration({
            customerBk: system!.customerId as string,
            systemBk: system!.rootContainerId as string,
            mailConfig: action.mailConfig,
          })
          .pipe(
            map((mailConfigResponse) =>
              IncidentsDetectionActions.updateMailNotificationConfigSuccess({
                mailConfig: { is_enabled: mailConfigResponse.data.is_enabled },
              })
            ),
            catchError((error) => of(IncidentsDetectionActions.updateMailNotificationConfigFailure({ error })))
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private incidentsDetectionService: IncidentsDetectionService,
    protected store: Store
  ) {}

  private parseOverview(overview: IncidentComponentOverview): IncidentComponentOverview {
    return this.fillDisplayBreaches(overview);
  }
  private fillDisplayBreaches(overview: IncidentComponentOverview) {
    overview.aggregates.forEach(
      (component) =>
        (component.displayBreaches = [
          ...component.upperBreaches.map((value) => `Upper threshold ${value}`),
          ...component.lowerBreaches.map((value) => `Lower threshold ${value}`),
        ])
    );
    return overview;
  }
}
