<ul
  nz-menu
  nzMode="inline"
  nzTheme="light"
  [nzInlineCollapsed]="isCollapsed"
  (nzCollapsedChange)="collapse($event)"
  *ngIf="showSideNav"
  class="h-full flex flex-col border-none"
>
  <ng-container [ngSwitch]="customerType">
    <!-- ENERGY -->
    <ng-container *ngSwitchCase="CustomerType.ENERGY">
      <li
        nz-submenu
        nzOpen
        [nzTitle]="submenuTitle"
        nzMatchRouter
        class="prevent-collapse"
        [attr.data-intercom-target]="intercomTarget + '-asset-dashboard-solution'"
      >
        <ng-template #submenuTitle>
          <a
            nzMatchRouter
            class="overwrite-link-styling flex items-center gap-8 mr-24"
            [routerLink]="'/energy/overview'"
            routerLinkActive
            #active="routerLinkActive"
            [queryParamsHandling]="'preserve'"
            (click)="preventDefault($event)"
          >
            <twaice-fe-svg-icon [ngClass]="{ link: active.isActive }" name="server" source="tabler" width="16px" height="16px" />
            <span [ngClass]="{ invisible: isCollapsed, link: active.isActive }">Asset Dashboard</span>
          </a>
        </ng-template>

        <ul>
          <li
            nz-menu-item
            nzMatchRouter
            nz-tooltip
            nzTooltipPlacement="right"
            [nzTooltipTitle]="isCollapsed ? 'Safety' : ''"
            [attr.data-intercom-target]="intercomTarget + '-safety-solution'"
          >
            <a
              *featureToggle="'!ENERGY_SAFETY_OVERVIEW'"
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/incidents'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="alert-triangle" source="tabler" width="16px" height="16px" />
              <span [ngClass]="{ invisible: isCollapsed }">Safety</span>
            </a>
            <a
              *featureToggle="'ENERGY_SAFETY_OVERVIEW'"
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/safety'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="alert-triangle" source="tabler" width="16px" height="16px" />
              <span [ngClass]="{ invisible: isCollapsed }">Safety</span>
            </a>
          </li>
          <li nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-health-solution'">
            <a
              class="overwrite-link-styling flex items-center gap-8 h-40"
              [routerLink]="'/energy/health'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon nz-icon name="icon_health" width="16px" height="16px" />
              <span>Health</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-performance-manager-solution'">
            <a
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/performance-manager'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="chart-arrows-vertical" width="16px" height="16px" />
              <span class="mr-16">Performance</span>
            </a>
          </li>

          <li
            nz-menu-item
            nzMatchRouter
            *featureToggle="'ENERGY_NAVIGATION_SHOW_PREDICTION'"
            [attr.data-intercom-target]="intercomTarget + '-health-prediction-solution'"
          >
            <a
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/health-prediction/health-prediction'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="icon_health_prediction" width="16px" height="16px" />
              <span>Prediction</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-warranty-solution'">
            <a
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/energy/warranty'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="icon_warranty" width="16px" height="16px" />
              <span>Warranty</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouter [attr.data-intercom-target]="intercomTarget + '-data-explorer-solution'">
            <a
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/storage-monitoring/data-explorer'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="icon_data_explorer" width="16px" height="16px" />
              <span>Data Explorer</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouter
              *featureToggle="'ENERGY_SHOW_DATA_EXPLORER_V2'"
              [attr.data-intercom-target]="intercomTarget + '-data-explorer-v2-solution'"
          >
            <a
              class="overwrite-link-styling flex items-center gap-8"
              [routerLink]="'/storage-monitoring/data-explorer-v2'"
              [queryParamsHandling]="'preserve'"
            >
              <twaice-fe-svg-icon name="icon_data_explorer" width="16px" height="16px" />
              <span>Data Explorer V2</span>
            </a>
          </li>
        </ul>
      </li>

      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Reports' : ''"
        [attr.data-intercom-target]="intercomTarget + '-reports-solution'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8"
          [routerLink]="'/reporting/library'"
          [queryParamsHandling]="'preserve'"
        >
          <twaice-fe-svg-icon name="file-analytics" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">Reports</span>
        </a>
      </li>
    </ng-container>

    <ng-container *ngSwitchCase="CustomerType.MOBILITY">
      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Home' | translate: { _key: 'fleet.nav.home' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-home'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8"
          [routerLink]="'/mobility-explore/overview'"
          [queryParamsHandling]="'preserve'"
        >
          <twaice-fe-svg-icon name="home" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Home' | translate: { _key: 'fleet.nav.home' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        [nzSelected]="isPartOfExploreAndCompare"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Explore & Compare' | translate: { _key: 'fleet.nav.explore' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-explore-compare-solution'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8"
          [routerLink]="'/mobility-explore/table'"
          [queryParamsHandling]="'preserve'"
        >
          <twaice-fe-svg-icon name="directions" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Explore & Compare' | translate: { _key: 'fleet.nav.explore' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Safety' | translate: { _key: 'fleet.nav.safety' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-safety-solution'"
        *featureToggle="'FLEET_ANALYTICS_ENABLE_SAFETY'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8"
          [routerLink]="'/mobility/alerts'"
          [queryParamsHandling]="'preserve'"
        >
          <twaice-fe-svg-icon name="alert-triangle" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Safety' | translate: { _key: 'fleet.nav.safety' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Reports' | translate: { _key: 'fleet.nav.reports' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-reports-solution'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8"
          [routerLink]="'/reporting/library'"
          [queryParamsHandling]="'preserve'"
        >
          <twaice-fe-svg-icon name="file-analytics" source="tabler" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }">{{ 'Reports' | translate: { _key: 'fleet.nav.reports' } }}</span>
        </a>
      </li>

      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('Configuration' | translate: { _key: 'fleet.nav.config' }) : ''"
        [attr.data-intercom-target]="intercomTarget + '-mobility-configuration-solution'"
      >
        <a
          class="overwrite-link-styling flex items-center gap-8"
          [routerLink]="'/mobility/config'"
          [queryParamsHandling]="'preserve'"
        >
          <twaice-fe-svg-icon name="icon_tool" width="16px" height="16px" />
          <span [ngClass]="{ invisible: isCollapsed }" class="mr-24">
            {{ 'Configuration' | translate: { _key: 'fleet.nav.config' } }}
          </span>
        </a>
      </li>
    </ng-container>
  </ng-container>

  <li
    nz-tooltip
    nzTooltipPlacement="right"
    [nzTooltipTitle]="isCollapsed ? ('Expand' | translate: { _key: 'fleet.nav.expand' }) : ''"
    class="mt-auto border-solid border-t-1 border-slate-500 py-16"
    [ngClass]="{ 'flex items-center pl-24': !isCollapsed, 'pl-32': isCollapsed }"
    [attr.data-intercom-target]="intercomTarget + '-collapse-sidebar-button'"
  >
    <a class="overwrite-link-styling flex items-center gap-8" (click)="collapse(!isCollapsed)">
      <twaice-fe-svg-icon
        [name]="isCollapsed ? 'layout-sidebar-right-collapse' : 'layout-sidebar-left-collapse'"
        source="tabler"
        width="16px"
        height="16px"
      />
      <span [ngClass]="{ invisible: isCollapsed }">{{ 'Collapse' | translate: { _key: 'fleet.nav.collapse' } }}</span>
    </a>
  </li>
</ul>
